import { useEffect, useState } from 'react';

export type OperatingSystem = 
  | 'Windows'
  | 'MacOS'
  | 'iOS'
  | 'Android'
  | 'Linux'
  | 'Unknown';

export function useDetectOperatingSystem (): OperatingSystem {
  const [ os, setOs ] = useState<OperatingSystem>('Unknown');

  useEffect(() => {
    setOs(() => {
      const userAgent = window.navigator.userAgent;

      if (/Win\d{2}|Windows/.test(userAgent)) {
        return 'Windows';
      } else if (/Mac OS|Macintosh/.test(userAgent)) {
        return 'MacOS';
      } else if (/iPhone|iPad|iPod/.test(userAgent)) {
        return 'iOS';
      } else if (/Android/.test(userAgent)) {
        return 'Android';
      } else if (/Linux/.test(userAgent)) {
        return 'Linux';
      } else {
        return 'Unknown';
      }
    });
  }, []);

  return os;
}
