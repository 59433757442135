import { Block, ButtonLink, Flex, IntrinsicProps, createComponent } from 'react-commons';
import Image from 'next/image';
import style from './index.module.scss';
import GamepadIcon from '../GamepadIcon';
import { ExtendedGameData } from '@/lib/drupal/models/Games';
import GameTileImg from '@/components/GameTileImg';
import { useRouter } from 'next/router';

interface DiscontinuedGameProps extends IntrinsicProps {
  game: ExtendedGameData
}

export default createComponent<DiscontinuedGameProps>('DiscontinuedGame', { style }, function DiscontinuedGameProps ({ className }, props) {
  const router = useRouter();

  const navigateToGame = () => {
    router.push(props.game.href);
  };

  return (
    <div className={className + ' --padMd2'}>
      <Flex alignCenter justifyCenter directionColumn container gap1>
        <Flex>
          <p className='--textCenter'>
            We're sorry this flash game has been discontinued.
            <br />
            We suggest this game instead:
          </p>
        </Flex>
        <Flex directionColumn alignCenter gap0>
          <GameTileImg 
            onClick={navigateToGame}
            src={props.game.thumbnailImgXl}
            title={`${props.game.title} thumbnail`}
            extraLarge
          />
          <div>
            <ButtonLink 
              href={props.game.href} 
              secondary 
              small 
              style={{ marginTop: 10 }}
            >
              Play Now
              <GamepadIcon />
            </ButtonLink>
          </div>
        </Flex>
      </Flex>
    </div>
  );
});
