import axios from '@/lib/drupal/api/axiosInstance';
import { AxiosResponse } from 'axios';
import { logErrorEvent } from 'react-commons';
import { UserData } from './Users';
import { SortCommentsBy } from '@/lib/hooks/useCommentList';
import { GamePageLayout } from '@/lib/drupal/models/Games';
import { isTruthy } from '@/lib/util/isTruthy';
import { decodeHtmlEntities } from '@/lib/util/decodeHtmlEntities';

export const NUM_COMMENTS_PER_PAGE = 10;

interface DrupalCommentData {
  cid: string
  subject: string
  comment_body: string
  created: string
  author: {
    uid: number
    name: string
    url: string
    picture: string
  }
  anonymous_user_mail: string
  status: string
  thread: string
  flag_count_helpful: string
  flag_count_unhelpful: string
  sw_comment_user_game_tag: string
  comment_helpful_user_flagging_status: string
  comment_unhelpful_user_flagging_status: string
}

export interface CommentData {
  uid: string
  body: string
  author: Pick<UserData, 'uid' | 'displayName' | 'avatarUrl' | 'profileHref'>
  created: string
  userRating?: 'helpful' | 'unhelpful'
}

type CommentNode = 'comment_node_free_game' | 'comment_node_daily_game' | 'comment_node_paid_game'

export default class CommentModel {
  static transform (rawData: DrupalCommentData): CommentData {
    const avatarUrl = rawData.author?.picture
      ? process.env.NEXT_PUBLIC_IMAGE_DOMAIN + rawData.author.picture
      : '/images/userAvatarPlaceholder.png';

    const userRating = (
      isTruthy(rawData.comment_helpful_user_flagging_status) || 
      isTruthy(rawData.comment_unhelpful_user_flagging_status)
    )
      ? (
        isTruthy(rawData.comment_helpful_user_flagging_status) 
          ? 'helpful' 
          : 'unhelpful'
      )
      : undefined;

    const author = rawData.author 
      ? {
        uid: rawData.author.uid.toString(),
        displayName: rawData.author.name,
        avatarUrl,
        profileHref: rawData.author.url,
      }
      : {
        uid: '0',
        displayName: 'Guest',
        avatarUrl: '/images/userAvatarPlaceholder.png',
        profileHref: '',
      };

    return {
      uid: rawData.cid,
      body: decodeHtmlEntities(rawData.comment_body || ''),
      author,
      created: rawData.created,
      userRating,
    };
  }

  static async getCommentsForGame (gameId: string, page: number, sortBy: SortCommentsBy): Promise<CommentData[]> {
    let response: AxiosResponse;
    try {
      response = await axios.get(
        `/api/game/comment/node/${gameId}?` +
        `page=${page}&` +
        `sort_by=${sortBy}&` +
        'sort_order=DESC&' + 
        `items_per_page=${NUM_COMMENTS_PER_PAGE}`
      );
    } catch (error) {
      logErrorEvent('Get Comments', false, error);
      throw error;
    }

    return response.data.rows.map((rawData: DrupalCommentData) => CommentModel.transform(rawData));
  }

  static async leaveComment (gameId: string, gamePageLayout: GamePageLayout, comment: string): Promise<void> {
    let nodeType: CommentNode = 'comment_node_free_game';
    if (gamePageLayout === 'jigsaw' || gamePageLayout === 'daily') nodeType = 'comment_node_daily_game';
    if (gamePageLayout === 'download') nodeType = 'comment_node_paid_game';

    const body = {
      comment_body: [
        {
          value: comment,
          format: 'plain_text'
        }
      ],
      subject: [ { value: '' } ],
      comment_type: [ { target_id: nodeType } ],
      field_name: [ { value: nodeType } ],
      entity_type: [ { value: 'node' } ],
      entity_id: [ { target_id: gameId } ]
    };

    try {
      return await axios.post('/comment', body);
    } catch (error) {
      logErrorEvent('Leave Comment', false, error);
      throw error;
    }
  }
}
