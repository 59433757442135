import { useRef, useState, useEffect } from 'react';
import CommentModel, { CommentData, NUM_COMMENTS_PER_PAGE } from '../drupal/models/Comments';
import { useLoadingState } from './useLoadingState';

export const SORT_BY = {
  created: 'Most Recent',
  count_flag_helpful: 'Most Helpful',
};

export type SortCommentsBy = keyof typeof SORT_BY;

export function useCommentList (gameId: string) {
  const initialRenderRef = useRef(true);

  const [ hasReachedEnd, setHasReachedEnd ] = useState(false);
  const [ page, setPage ] = useState(1);
  const [ isLoading, startLoading, finishLoading ] = useLoadingState();

  // Sort state
  const lastSortRef = useRef<SortCommentsBy>('created');
  const [ sortBy, setSortByDispatch ] = useState<SortCommentsBy>('created');

  const setSortBy = (newSortBy: SortCommentsBy) => {
    setSortByDispatch(newSortBy);
  };

  // Comment state
  const [ comments, setComments ] = useState<CommentData[]>([]);

  // Methods
  const initialCallRef = useRef(false);
  const fetchInitialComments = async () => {
    if (initialCallRef.current) {
      return;
    }

    initialCallRef.current = true;

    (async () => {
      const initialComments = await CommentModel.getCommentsForGame(gameId, 0, 'created');
      setComments(initialComments);
      if (initialComments.length < NUM_COMMENTS_PER_PAGE) setHasReachedEnd(true);
      initialRenderRef.current = false;
    })();
  };

  const fetchNextComments = () => {
    setPage((currentPage) => currentPage + 1);
  };

  // Fetch more comments when page or sort changes
  useEffect(() => {
    if (initialRenderRef.current) {
      const fetchInitial = async () => {
        const initialComments = await CommentModel.getCommentsForGame(gameId, 0, 'created');
        setComments(initialComments);
        initialRenderRef.current = false;
      };
      fetchInitial();
      return;
    }

    if (sortBy !== lastSortRef.current) {
      setHasReachedEnd(false);
      setPage(0);
      lastSortRef.current = sortBy;

      if (page !== 0) return;
    }

    const fetchNext = async () => {
      startLoading();
      
      const newComments = await CommentModel.getCommentsForGame(gameId, page, sortBy);
  
      if (newComments.length === 0) {
        setHasReachedEnd(true);
      } else {
        if (page === 0) setComments(newComments);
        else setComments((currentComments) => currentComments.concat(newComments));
      }
  
      finishLoading();
    };
    fetchNext();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ page, sortBy ]);

  return {
    isLoading,
    sortBy,
    setSortBy,
    hasReachedEnd,
    comments,
    fetchInitialComments,
    fetchNextComments,
  };
}
