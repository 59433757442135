import { Reducer, useEffect, useReducer, useRef, useState } from 'react';
import { useEffectOnce } from 'react-commons';
import SwagModel, { GameModeData, LeaderboardData, LeaderboardPeriod, UserBestData } from '@/lib/swag/models/Swag';
import { useAuthStore } from '@/lib/drupal/stores/auth';
import { useEffectAfterFirstRender } from './useEffectAfterFirstRender';
import { useLoadingState } from './useLoadingState';
import { useCurrentDay } from '@/lib/hooks/useDailyArchive';
import { DailyGameFields } from '@/lib/drupal/models/Games';
import { dailyFieldsToDateKey } from '@/components/gamePage/Archive';
import { useRouter } from 'next/router';

// 'getDays': /days?game=${gameId}&limit=30

// 'getGameModes': /score/categories?game=${gameId}

// 'getDailyScores': /scores?game=${gameId}&day=${day}&type=daily&level_key=${levelKey}&period=alltime

export enum LeaderboardToolbarStateActionType {
  SET_GAME_MODES,
  SET_DAILY_GAME_MODES,
  SET_CURRENT_GAME_MODE,
  SET_CURRENT_PERIOD,
  SET_SHOW_USER_SCORES,
};

interface LeaderboardToolbarStateAction {
  type: LeaderboardToolbarStateActionType
  payload: any
};

export interface LeaderboardToolbarState {
  gameModes: GameModeData[]
  gameModeType: GameModeData[ 'columnType' ] | ''
  gameModeLabel: string
  currentGameMode: string
  currentPeriod: LeaderboardPeriod
  showUserScores: boolean
};

export interface LeaderboardTableData {
  columnType: GameModeData[ 'columnType' ] | ''
  columnLabel: string
  scores: LeaderboardData[]
  userBest: UserBestData | null
};

export interface UpdateToolbarStateFn {
  (action: LeaderboardToolbarStateActionType, payload: any): void
};

interface UseLeaderboardsOptions {
  dailyLevelKey?: string
}

export function useLeaderboards (gameKeyword: string, options: UseLeaderboardsOptions = {}) {
  const gameIdRef = useRef<string>('');

  const [ authState ] = useAuthStore();
  const [ error, setError ] = useState<Error | undefined>(undefined);
  const [ isLoading, startLoading, finishLoading ] = useLoadingState();
  const [ fields, _today ] = useCurrentDay();

  // Table data
  const [ tableData, setTableData ] = useState<LeaderboardTableData>({
    columnType: '',
    columnLabel: '',
    scores: [],
    userBest: null,
  });

  // Toolbar state
  const [ toolbarState, dispatchToolbarState ] = useReducer<Reducer<LeaderboardToolbarState, LeaderboardToolbarStateAction>>((state, action) => {
    switch (action.type) {
    case LeaderboardToolbarStateActionType.SET_GAME_MODES: {
      return {
        ...state,
        gameModes: action.payload,
        gameModeType: action.payload[ 0 ].columnType,
        gameModeLabel: action.payload[ 0 ].columnLabel,
        currentGameMode: action.payload[ 0 ].key,
      };
    }
    case LeaderboardToolbarStateActionType.SET_DAILY_GAME_MODES: {
      return {
        ...state,
        gameModes: action.payload.gameModes,
        gameModeType: action.payload.currentGameMode.columnType,
        gameModeLabel: action.payload.currentGameMode.columnLabel,
        currentGameMode: action.payload.currentGameMode.key,
      };
    }
    case LeaderboardToolbarStateActionType.SET_CURRENT_GAME_MODE: {
      return {
        ...state,
        currentGameMode: action.payload,
      };
    }
    case LeaderboardToolbarStateActionType.SET_CURRENT_PERIOD: {
      return {
        ...state,
        currentPeriod: action.payload,
      };
    }
    case LeaderboardToolbarStateActionType.SET_SHOW_USER_SCORES: {
      return {
        ...state,
        showUserScores: action.payload,
      };
    }
    }
  }, {
    gameModes: [],
    gameModeType: '',
    gameModeLabel: '',
    currentGameMode: '',
    currentPeriod: 'daily',
    showUserScores: false,
  });

  // Methods
  const fetchGameId = async () => {
    try {
      const gameId = await SwagModel.getGameId(gameKeyword);
      gameIdRef.current = gameId;
    } catch (err) {
      throw err;
    }
  };

  const fetchGameModes = async () => {
    try {
      let data = await SwagModel.getGameModes(gameIdRef.current);

      if (options.dailyLevelKey) {
        const currentGameMode = data.find((gameMode) => gameMode.key === options.dailyLevelKey);
        data = await SwagModel.getDays(gameIdRef.current, currentGameMode.columnType);

        dispatchToolbarState({
          type: LeaderboardToolbarStateActionType.SET_DAILY_GAME_MODES,
          payload: {
            gameModes: data,
            currentGameMode: {
              key: dailyFieldsToDateKey(fields),
              label: dailyFieldsToDateKey(fields),
              columnType: currentGameMode.columnType,
              columnLabel: currentGameMode.columnLabel,
            },
          }
        });
      } else {
        dispatchToolbarState({
          type: LeaderboardToolbarStateActionType.SET_GAME_MODES,
          payload: data
        });
      }
    } catch (err) {
      throw err;
    }
  };

  const fetchTableData = async () => {
    try {
      let data;

      if (toolbarState.showUserScores) {
        data = await SwagModel.getUserScores(gameIdRef.current, toolbarState.currentGameMode, toolbarState.currentPeriod);
      } else {
        if (options.dailyLevelKey) {
          data = await SwagModel.getDailyScores(gameIdRef.current, toolbarState.currentGameMode, options.dailyLevelKey, 'alltime');
        } else {
          data = await SwagModel.getScores(gameIdRef.current, toolbarState.currentGameMode, toolbarState.currentPeriod);
        }
      }

      setTableData((prevTableData) => ({
        columnType: toolbarState.gameModeType,
        columnLabel: toolbarState.gameModeLabel,
        scores: data,
        userBest: prevTableData.userBest,
      }));
    } catch (err) {
      throw err;
    }
  };

  const fetchUserBest = async () => {
    try {
      const userBest = await SwagModel.getUserBest(gameIdRef.current, toolbarState.currentGameMode, toolbarState.currentPeriod);

      setTableData((prevTableData) => ({
        ...prevTableData,
        userBest,
      }));
    } catch (err) {
      throw err;
    }
  };
  
  const updateToolbarState: UpdateToolbarStateFn = (action: LeaderboardToolbarStateActionType, payload: any) => {
    dispatchToolbarState({
      type: action,
      payload,
    });
  };

  const retry = async () => {
    setError(undefined);
    startLoading(true);

    try {
      await fetchGameModes();
    } catch (err) {
      setError(err);
      finishLoading();
    }
  };

  // Load the game modes and initial table data once on first render
  useEffectOnce(() => {
    const fetch = async () => {
      setError(undefined);
      startLoading(true);

      try {
        await fetchGameId();
        await fetchGameModes();
      } catch (err) {
        setError(err);
        finishLoading();
      }
    };
    fetch();
  });

  // Keep game ID in sync
  useEffectAfterFirstRender(() => {
    if (!gameKeyword) return;

    const fetch = async () => {
      setError(undefined);
      startLoading();

      try {
        await fetchGameId();
        await fetchGameModes();
      } catch (err) {
        setError(err);
      }

      finishLoading();
    };
    fetch();
  }, [ gameKeyword ]);

  // Refresh table data with new criteria after toolbar state changes
  useEffect(() => {
    if (!toolbarState.currentGameMode) return;
    
    const fetch = async () => {
      setError(undefined);
      startLoading();

      try {
        await fetchTableData();
      } catch (err) {
        setError(err);
      }

      finishLoading();
    };
    fetch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ gameKeyword, toolbarState ]);

  // Keep user data in sync
  useEffect(() => {
    if (!authState.ready) return;
    if (!toolbarState.currentGameMode) return;

    if (authState.user) {
      const fetch = async () => {
        setError(undefined);
  
        try {
          await fetchUserBest();
        } catch (err) {
          setError(err);
        }
      };
      fetch();
    } else {
      setTableData((prevTableData) => ({
        ...prevTableData,
        userBest: null,
      }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ authState, gameKeyword, toolbarState ]);

  return {
    isLoading,
    error,
    toolbarState,
    tableData,
    updateToolbarState,
    retry,
  };
};