import { createComponent, If } from 'react-commons';

import style from './index.module.scss';

export default createComponent('Table', { style }, function Table ({ className, slots }) {
  return (
    <table className={className}>
      {
        If(slots.header, () => (
          <thead>
            {slots.header}
          </thead>
        )).EndIf()
      }
      <tbody>
        {slots.defaultSlot}
      </tbody>
    </table>  
  );
});
