import { AdUnit, useHidePlaywireSlotsOnResize, usePlaywireNewPageEvent } from '@/components/playwire/Playwire';
import style from './index.module.scss';

import { createComponent, IntrinsicProps } from 'react-commons';
import { useRef } from 'react';

interface PlaywireUnitProps extends IntrinsicProps {
  unit: { type: AdUnit, selectorId: string }
}

export default createComponent<PlaywireUnitProps>('PlaywireUnit', { style }, function PlaywireUnit ({}, props) {
  usePlaywireNewPageEvent(
    [ props.unit ]
  );

  const ref = useRef<HTMLDivElement>(null);
  useHidePlaywireSlotsOnResize(ref);

  return (
    <div ref={ref} className='PlaywireUnit'>
      <div id={props.unit.selectorId} />
    </div>
  );
});
