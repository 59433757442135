import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { useRef, useState } from 'react';
import { If, createComponent, IntrinsicProps, IconFA } from 'react-commons';

import style from './index.module.scss';

interface ClientErrorProps extends IntrinsicProps {
  error?: Error
  onTryAgain?: () => void
}

export default createComponent<ClientErrorProps>('ClientError', { style }, function ClientError ({ className }, props) {
  const [ showError, setShowError ] = useState(false);
  const hasLoggedRef = useRef(false);

  if (props.error && !hasLoggedRef.current) {
    hasLoggedRef.current = true;
  }

  return (
    <div className={className}>
      <h6>
        {
          If(props.onTryAgain, () => (
            <>
              Oops! An error has occurred, <span className='RouterLink' onClick={props.onTryAgain}>try again</span> later.
            </>
          ))
            .Else(() => (
              <>Oops! An error has occurred, try again later.</>
            ))
            .EndIf()
        }
        <br />
        If the problem persists please <a href='/' target='_blank' className='RouterLink'>contact support</a>.
      </h6>
      {
        If(props.error, () => (
          <>
            {
              If(showError, () => (
                <>
                  <p onClick={() => setShowError(false)}>
                    <IconFA icon={faCaretUp} small />
                  </p>
                  <pre>{props.error.stack || props.error.message}</pre>
                </>
              ))
                .Else(() => (
                  <p onClick={() => setShowError(true)}>
                    <IconFA icon={faCaretDown} small />
                  </p>
                ))
                .EndIf()
            }
          </>
        )).EndIf()
      }
    </div>
  );
});
