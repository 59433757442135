import { useAppStore } from '@/lib/stores/app';

export interface GenreBreadcrumb {
  href: string
  text: string
}

export function useGenreBreadcrumbs (genreIds: string[], isDownloadGame: boolean, excludeTopLevel?: boolean): GenreBreadcrumb[] {
  const [ appState ] = useAppStore();

  const genresToBreadcrumbs = () => {
    return genreIds
      .map((genreId) => {
        let genre;
  
        if (isDownloadGame) {
          genre = appState.taxonomies.downloadGenres.find((g) => g.uid === genreId);
        } else {
          genre = appState.taxonomies.onlineGenres.find((g) => g.uid === genreId);
        }
  
        if (!genre) {
          if (excludeTopLevel) {
            return undefined;
          }

          return {
            href: isDownloadGame
              ? '/download/all-games'
              : '/online/all-games',
            text: '',
          };
        }
  
        return {
          href: genre.href,
          text: genre.name + ' Games',
        };
      })
      .filter(
        (genre) => !!genre
      );
  };

  return genresToBreadcrumbs();
}

export function useGameBreadcrumbs (genreIds: string[], isDownloadGame: boolean, excludeTopLevel?: boolean): GenreBreadcrumb[] {
  const [ appState ] = useAppStore();

  const genresToBreadcrumbs = () => {
    return genreIds
      .map((genreId) => {
        let genre;
  
        if (isDownloadGame) {
          genre = appState.taxonomies.downloadGenres.find((g) => g.uid === genreId);
        } else {
          genre = appState.taxonomies.onlineGenres.find((g) => g.uid === genreId);
        }
  
        if (!genre) {
          if (excludeTopLevel) {
            return undefined;
          }

          return {
            href: isDownloadGame
              ? '/download'
              : '/online',
            text: '',
          };
        }
  
        const result = {
          href: genre.href,
          text: genre.name + ' Games',
        };

        return result;
      })
      .filter(
        (genre) => !!genre
      );
  };

  return genresToBreadcrumbs();
}
