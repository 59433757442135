import Table from '@/components/Table';
import { UserData } from '@/lib/drupal/models/Users';
import { getZonedDate } from '@/lib/util/zonedTime';
import { useState } from 'react';
import { Block, Button, Buttons, Checkbox, Control, createComponent, Field, FieldLabel, FieldType, Flex, For, Form, If, Input, InputField, InputType, ModalCard, Slot, Textarea, TextareaField, useForm } from 'react-commons';

// import style from './index.module.scss';

interface Friend extends UserData {
  checked?: boolean
}

export default createComponent('LeaderboardTable', {}, function LeaderboardTable ({}, props) {
  const [ currentTab, setCurrentTab ] = useState<'friends' | 'email'>('friends');

  const [ friends, setFriends ] = useState<Friend[]>([
    {
      uid: '',
      email: 'email@email.com',
      tokenBalance: 0,
      displayName: 'Shockwave User',
      avatarUrl: 'https://www.shockwave.com/sites/default/files/pictures/picture-5851011-1608676049.jpg',
      isPremiumUser: false,
      createdAt: (getZonedDate()).getTime(),
    } as Friend,
  ]);
  
  const form = useForm({
    name: {
      value: '',
      schema: {
        type: FieldType.ANY,
        required: true,
      }
    },
    recipientEmails: {
      value: '',
      schema: {
        type: FieldType.ANY,
        required: true,
      }
    },
    recipientFriends: {
      value: [],
      schema: {
        type: FieldType.LIST,
        minRequired: 1,
      },
    },
    message: {
      value: '',
      schema: {
        type: FieldType.ANY,
      }
    }
  });

  const toggleAllFriends = (isChecked: boolean) => {
    // TODO: Update all friends in friends array
  };

  const toggleFriend = (friend: Friend, isChecked: boolean) => {
    // TODO: Update friend in friends array
  };

  // const checkboxFieldChanged = (key, value, trueValue) => {
  //   const list = form.data[ key ];
  //   if (value) {
  //     list.push(trueValue);
  //   } else {
  //     const index = list.indexOf(trueValue);
  //     list.splice(index, 1);
  //   }
  //   form.validateField(key, list);
  // };

  const formSubmitted = async () => {
    // TODO
  };

  return (
    <ModalCard>
      <Slot name='title'>Invite your friends to play games with you on Shockwave!</Slot>
      <Form
        onSubmit={() => { throw new Error('not implemented'); }}
      >
        <Block>
          <Buttons addons>
            <Button 
              secondary={currentTab === 'friends'} 
              medium 
              onClick={() => setCurrentTab('friends')}
              className='Button--secondaryOutline'
            >
              Shockwave Friends
            </Button>
            <Button 
              secondary={currentTab === 'email'} 
              medium 
              onClick={() => setCurrentTab('email')}
              className='Button--secondaryOutline'
            >
              By Email
            </Button>
          </Buttons>
        </Block>
        {
          If(currentTab === 'friends', () => (
            <>
              <Block>
                <Table>
                  <Slot name='header'>
                    <tr>
                      <th>
                        <Checkbox 
                          onChange={toggleAllFriends}
                          value={friends.every(friend => friend.checked)}
                          trueValue={true}
                          falseValue={false}
                        />
                      </th>
                      <th>Name</th>
                      <th>Email</th>
                    </tr>
                  </Slot>
                  <>
                    {
                      For(friends, (friend: Friend) => (
                        <tr key={friend.uid}>
                          <td>
                            <Checkbox 
                              onChange={(value) => toggleFriend(friend, value)} 
                              value={friend.checked} 
                              trueValue={true}
                              falseValue={false}
                            />
                          </td>
                          <td>{friend.displayName}</td>
                          <td>{friend.email}</td>
                        </tr>
                      ))
                    }
                  </>
                </Table>
              </Block>
            </>
          ))
            .Else(() => (
              <>
                <Block>
                  <InputField 
                    form={form} 
                    type={InputType.ANY} 
                    name='name' 
                    placeholder='Your Name' 
                    className='Input--medium' 
                    style={{ width: '250px' }}
                  >
                    <Slot name='icon'><span /></Slot>
                  </InputField>
                  <InputField 
                    form={form} 
                    type={InputType.ANY} 
                    name='recipientEmails' 
                    placeholder='Recipient Email(s)' 
                    className='Input--medium' 
                    style={{ width: '250px' }}
                  >
                    <Slot name='icon'><span /></Slot>
                  </InputField>
                </Block>
              </>
            ))
            .EndIf()
        }
        <Block>
          <TextareaField 
            name='message' 
            form={form} 
            className='Input--medium'
          >
            <Slot name='label'>Add a personal message (optional)</Slot>
          </TextareaField>
        </Block>
        <Block>
          <Flex wide>
            <Flex pullRight>
              <Button medium secondary submit>Send Invitation</Button>
            </Flex>
          </Flex>
        </Block>
      </Form>
    </ModalCard>
  );
});
