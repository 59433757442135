import React from 'react';
import ClientError from '@/components/ClientError';
import { useAuthStore } from '@/lib/drupal/stores/auth';
import { useAuthMethods } from '@/lib/hooks/useAuthMethods';
import { LeaderboardTableData } from '@/lib/hooks/useLeaderboards';
import { LeaderboardData } from '@/lib/swag/models/Swag';
import { faArrowCircleDown, faArrowCircleUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { Block, Button, createComponent, Flex, For, IconFA, If, IntrinsicProps, Loader, Modal, Slot, useModal } from 'react-commons';
import Table from '../Table';
import UserAvatar from '../UserAvatar';
import ChallengeFriendsModal from './ChallengeFriendsModal';

import style from './index.module.scss';
import SwPaginator from '../SwPaginator';

interface LeaderboardTableProps extends IntrinsicProps {
  isLoading: boolean
  error?: Error
  tableData: LeaderboardTableData
  retry: () => void
  compact?: boolean
}

const classStates = [
  'isLoading'
];

export default createComponent<LeaderboardTableProps>('LeaderboardTable', { style, classStates }, function LeaderboardTable ({ className }, props) {
  const [ authState ] = useAuthStore();
  const { login } = useAuthMethods();
  const { openModal } = useModal();
  const [ showMore, setShowMore ] = useState(false);

  const [ currentPage, setCurrentPage ] = useState(0);

  const showChallengeFriendsModal = () => {
    openModal('challengeFriends');
  };

  const PAGE_SIZE = 10;

  const numScores = props.tableData.scores.length;
  const numPages = Math.ceil(numScores / PAGE_SIZE);

  const scores = props.tableData.scores.slice(currentPage * PAGE_SIZE, currentPage * PAGE_SIZE + PAGE_SIZE);
  
  return (
    <div className={className}>
      <Modal name='challengeFriends'>
        <ChallengeFriendsModal />
      </Modal>
      
      {
        If(!props.compact, () => (
          <Block className='LeaderboardTable__UserBlock'>
            {
              If(authState.ready && authState.user, () => (
                <Flex gap0 alignCenter justifyCenter>
                  <Flex gap0>
                    <Flex>
                      <UserAvatar 
                        small
                        src={authState.user.avatarUrl} 
                        displayName={authState.user.displayName}
                      />
                    </Flex>
                    <Flex justifyCenter directionColumn>
                      {
                        If(props.isLoading, () => (
                          <>
                            <p>
                              <strong>Your Best Score Today</strong>
                            </p>
                            <p>
                              Loading your best scores...
                            </p>
                          </>
                        ))
                          .ElseIf(props.tableData.userBest?.dailyBest, () => (
                            <>
                              <p>
                                <strong>Your Best Score Today</strong> &mdash; {props.tableData.userBest.dailyBest}
                              </p>
                              <p>
                                You placed <strong>#{props.tableData.userBest.scorePosition}</strong> out of <strong>{props.tableData.userBest.totalScores}</strong> scores today.
                              </p>
                            </>
                          ))
                          .Else(() => (
                            <>
                              <p>
                                <strong>No Scores Posted Today</strong>
                              </p>
                              <p>
                                Play a game and share your score.
                              </p>
                            </>
                          ))
                          .EndIf()
                      }
                    </Flex>
                  </Flex>

                  {/* TODO: SWQA-1160 challenge friend modal */}
                  {/* <Flex>
                    <Button secondary small onClick={showChallengeFriendsModal}>
                      Challenge Your Friends
                    </Button>
                  </Flex> */}
                </Flex>
              ))
                .Else(() => (
                  <Flex gap0 alignCenter justifyCenter>
                    {/* TODO: SWQA-1160 challenge friend modal */}
                    <p>
                      Log in to see your high scores, friends' scores, and token earnings.
                    </p>
                    <Button small secondary onClick={login}>
                      Log In
                    </Button>
                  </Flex>
                ))
                .EndIf()
            }
          </Block>
        )).EndIf()
      }
      
      {
        If(props.error, () => (
          <ClientError
            error={props.error}
          />
        )).EndIf()
      }

      {
        If(!props.error, () => (
          <Table>
            <Slot name='header'>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>{props.tableData.columnLabel}</th>
                <th>Date</th>
              </tr>
            </Slot>
            <>
              {
                If(props.isLoading && !props.tableData.scores.length, () => (
                  <tr>
                    <td colSpan={4} className='Table--infoCell'>
                      <Loader large />
                    </td>
                  </tr>
                ))
                  .ElseIf(!props.isLoading && !props.tableData.scores.length, () => (
                    <tr>
                      <td colSpan={4} className='Table--infoCell'>
                        <p>
                          You don't have any high scores for this period.
                        </p>
                      </td>
                    </tr>
                  ))
                  .Else(() => (
                    <>
                      {
                        For(scores, (score: LeaderboardData, index: number) => (
                          <tr key={index}>
                            <td>{currentPage * PAGE_SIZE + index + 1}</td>
                            <td>
                              <strong>{score.displayName}</strong>
                              {
                                If(authState.ready && score.displayName === authState.user?.displayName, () => (
                                  <>&nbsp;(you)</>
                                )).EndIf()
                              }
                            </td>
                            <td>{score.value}</td>
                            <td>{score.dateCreated}</td>
                          </tr>
                        ))
                      }
                    </>
                  ))
                  .EndIf()
              }
            </>
          </Table>
        )).EndIf()
      }
      
      {
        If(!props.isLoading && !props.error && authState.user, () => (
          <Flex justifyCenter alignCenter>
            <SwPaginator currentPage={currentPage} numPages={numPages} onPaginate={function (currentPage: number, nextPage: number): void {
              setCurrentPage(nextPage);
            } } />
          </Flex>
        )).EndIf()
      }
    </div>
  );
});
