import { AdContainerSize, ButtonLink, Flex, IntrinsicProps, Title, createComponent, toClassName } from 'react-commons';

import style from './index.module.scss';
import { CaptureClickEvent, CaptureClickEventContext } from '@/lib/util/captureClickEvent';
import { useContext } from 'react';

export interface AdFallbackProps extends IntrinsicProps {
  size: AdContainerSize
}

const adContainerClassStates = [
  'banner',
  'mobileBanner',
  'halfPage',
  'inlineRect',
  'largeLeaderboard',
  'largeRect',
  'leaderboard',
  'mediumRect',
  'mobileLeaderboard',
  'skyscraper',
  'smallSquare',
  'square',
  'wideSkyscraper'
];

export default createComponent('AdFallback', { style }, ({ className, mergeClassNames }, props: AdFallbackProps) => {
  className = mergeClassNames(toClassName(className, adContainerClassStates, { [ props.size ]: true }));

  const captureClickEventCtx = useContext(CaptureClickEventContext);

  return (
    <Flex alignCenter justifyCenter className={className} style={props.style}>
      <Flex alignCenter directionColumn>
        <Title className='--textCenter'>We know Ads are annoying</Title>
        <p className='--textCenter'>Consider subscribing. You'll get access to archives, unlimited access to our downloadable games and we'll even remove ads too.</p>
        <br />
        <CaptureClickEvent
          action='upsell'
          location='hero_upsell'
          properties={() => ({
            tag_name: 'upsell_adblock_nag',
            title: captureClickEventCtx.settings.gameTitle ?? '',
            id: captureClickEventCtx.settings.gameId ?? '',
          })}
          href='/unlimited'
        >
          <ButtonLink 
            large secondary rounded 
            href='/unlimited' 
            className='upsell_adblock_nag'
          >
            Subscribe
          </ButtonLink>
        </CaptureClickEvent>
      </Flex>
    </Flex>
  );
});
