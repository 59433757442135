import { Button, createComponent, IconFA, If, IntrinsicProps, useNotifications } from 'react-commons';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as faHeartOutline } from '@fortawesome/free-regular-svg-icons';
import { useEffect, useState } from 'react';
import { useLoadingState } from '@/lib/hooks/useLoadingState';
import UsersModel from '@/lib/drupal/models/Users';
import { useAuthStore } from '@/lib/drupal/stores/auth';
import { useAuthMethods } from '@/lib/hooks/useAuthMethods';
import axiosInstance from '@/lib/drupal/api/axiosInstance';
import { captureEvent } from '@/lib/util/captureEvent';

interface FavoriteButtonProps extends IntrinsicProps {
  gameId: string
  gameTitle: string
  rounded?: boolean
}

export default createComponent<FavoriteButtonProps>('FavoriteButton', {}, function FavoriteButton ({}, props) {
  const { notify } = useNotifications({ lifeSpan: Number(process.env.NEXT_PUBLIC_NOTIFICATION_LIFESPAN) });
  const { login } = useAuthMethods();
  const [ authState ] = useAuthStore();
  const [ isLoading, startLoading, finishLoading ] = useLoadingState();
  const [ isFavorite, setIsFavorite ] = useState(false);

  useEffect(() => {
    if (isLoading) return;
    if (!authState.ready || !authState.auth) return;
    if (!axiosInstance.defaults.headers[ 'X-CSRF-Token' ]) return;

    (async () => {
      startLoading();
      try {
        const isFavorite = await UsersModel.isFavoriteGame(props.gameId);
        setIsFavorite(isFavorite);
      } catch {
        setIsFavorite(false);
      }
      finishLoading();
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ props.gameId, authState ]);

  const toggleFavorite = async () => {
    if (!authState.ready || !authState.user) {
      notify('You must be logged in to favorite games.');
      login();
      return;
    }

    const nextIsFavorite = !isFavorite;

    if (nextIsFavorite) {
      captureEvent('favorite_game', {
        tag_name: 'game_favorite_add',
        id: props.gameId,
        title: props.gameTitle,
      });
    }
    
    notify(nextIsFavorite 
      ? <>Added <strong>{props.gameTitle}</strong> to your favorites!</>
      : <>Removed <strong>{props.gameTitle}</strong> from your favorites.</>
    );
    setIsFavorite(nextIsFavorite);

    startLoading();
    try {
      await UsersModel.toggleFavoriteGame(props.gameId, nextIsFavorite);
    } catch {
      notify('There was an error favoriting this game. Please try again later.', 'error');
      setIsFavorite(!nextIsFavorite);
    }
    finishLoading();
  };

  return (
    <Button 
      small={!props.rounded}
      medium={props.rounded}
      primary={!props.rounded}
      secondary={props.rounded}
      loading={isLoading || !authState.ready}
      onClick={toggleFavorite}
      rounded={props.rounded}
    >
      {
        If(isFavorite, () => (
          <IconFA icon={faHeart} />
        ))
          .Else(() => (
            <IconFA icon={faHeartOutline} />
          ))
          .EndIf()
      }
    </Button>
  );
});
