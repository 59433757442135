import { displayUnits, useHidePlaywireSlotsOnResize, usePlaywireNewPageEvent } from '@/components/playwire/Playwire';
import style from './index.module.scss';

import { AdContainer, AdContainerSize, createComponent, If } from 'react-commons';
import { useEffect, useRef, useState } from 'react';
import AdFallback from '@/components/AdFallback';

export default createComponent('PlaywireAside', { style }, function PlaywireAside () {
  usePlaywireNewPageEvent(
    [
      { type: 'corner_ad_video' }, 
      { selectorId: 'playwire__aside__static', type: 'med_rect_atf' }
    ]
  );

  const ref = useRef<HTMLDivElement>(null);
  useHidePlaywireSlotsOnResize(ref);

  const [ showAdFallback, setShowAdFallback ] = useState(false);

  return (
    <>
      <AdContainer 
        size={AdContainerSize.MOBILE_BANNER} 
        onAdblockDetected={() => {
          setShowAdFallback(true);
        }}
        className='PlaywireAside__AdContainer'
      />
      <div ref={ref} className='PlaywireAside'>
        {
          If(showAdFallback, () => (
            <AdFallback size={AdContainerSize.MEDIUM_RECT} style={{ marginTop: '0' }} />
          )).Else(() => (
            <div id='playwire__aside__static' />
          )).EndIf()
        }
        <div />{/* this is a placeholder for video ads, do not remove */}
      </div>
    </>
  );
});

export const PlaywireAside2 = createComponent('PlaywireAside2', { style }, function PlaywireAside2 () {
  usePlaywireNewPageEvent(
    [
      { selectorId: 'playwire__aside__static2_1', type: 'med_rect_btf' },
      { selectorId: 'playwire__aside__static2_2', type: 'med_rect_btf' },
    ]
  );

  const ref = useRef<HTMLDivElement>(null);
  useHidePlaywireSlotsOnResize(ref);

  return (
    <>
      <div ref={ref} className='PlaywireAside2'>
        <div id='playwire__aside__static2_1' />
        <div id='playwire__aside__static2_2' />
      </div>
    </>
  );
});

// Modern layout game play view
export const PlaywireAside3 = createComponent('PlaywireAside3', { style }, function PlaywireAside3 () {
  usePlaywireNewPageEvent([
    { type: 'corner_ad_video' },
    { selectorId: 'playwire__aside__static3', type: 'med_rect_atf' }
  ]);
  useEffect(() => {
    displayUnits([
      { type: 'corner_ad_video' },
      { selectorId: 'playwire__aside__static3', type: 'med_rect_atf' }
    ]);
  }, []);

  const ref = useRef<HTMLDivElement>(null);
  useHidePlaywireSlotsOnResize(ref);

  const [ showAdFallback, setShowAdFallback ] = useState(false);

  return (
    <>
      <AdContainer 
        size={AdContainerSize.MOBILE_BANNER} 
        onAdblockDetected={() => {
          setShowAdFallback(true);
        }}
        className='PlaywireAside__AdContainer'
      />
      <div ref={ref} className='PlaywireAside'>
        {
          If(showAdFallback, () => (
            <AdFallback size={AdContainerSize.MEDIUM_RECT} style={{ marginTop: '0' }} />
          )).Else(() => (
            <div id='playwire__aside__static3' />
          )).EndIf()
        }
        <div />{/* this is a placeholder for video ads, do not remove */}
      </div>
    </>
  );
});

// Classic layout gameplay view
export const PlaywireAside4 = createComponent('PlaywireAside4', { style }, function PlaywireAside4 () {
  usePlaywireNewPageEvent([
    { type: 'corner_ad_video' },
    { selectorId: 'playwire__aside__static4', type: 'med_rect_atf' }
  ]);

  const ref = useRef<HTMLDivElement>(null);
  useHidePlaywireSlotsOnResize(ref);

  const [ showAdFallback, setShowAdFallback ] = useState(false);

  return (
    <>
      <AdContainer 
        size={AdContainerSize.MOBILE_BANNER} 
        onAdblockDetected={() => {
          setShowAdFallback(true);
        }}
        className='PlaywireAside__AdContainer'
      />
      <div ref={ref} className='PlaywireAside'>
        {
          If(showAdFallback, () => (
            <AdFallback size={AdContainerSize.MEDIUM_RECT} style={{ marginTop: '0' }} />
          )).Else(() => (
            <div id='playwire__aside__static4' />
          )).EndIf()
        }
        <div />{/* this is a placeholder for video ads, do not remove */}
      </div>
    </>
  );
});
